import React, { Component } from 'react'

export default class ServicesDetailHero extends Component {
  render() {
    const aboutImage = require('../../../assets/images/nastuh-abootalebi-284884-unsplash.jpg')

    return (
      <div
        className="gdlr-core-pbf-wrapper  gdlr-core-hide-in-mobile"
        style={{ padding: '300px 0px 30px 0px' }}
      >
        <div className="gdlr-core-pbf-background-wrap">
          <div
            className="gdlr-core-pbf-background gdlr-core-parallax gdlr-core-js"
            style={{
              backgroundImage: `url(${aboutImage})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              backgroundColor: this.props.withOpacity
                ? 'rgba(8, 169, 204, 0.5)'
                : '',
              backgroundBlendMode: this.props.withOpacity ? 'multiply' : '',
            }}
            data-parallax-speed={0}
          />
        </div>
        <div className="gdlr-core-pbf-wrapper-content gdlr-core-js ">
          <div className="gdlr-core-pbf-wrapper-container clearfix gdlr-core-container" />
        </div>
      </div>
    )
  }
}
