import React, { Component } from 'react'

export default class ServicesDetailApproachBlock extends Component {
  render() {
    return (
      <div
        className="gdlr-core-pbf-column gdlr-core-column-30 gdlr-core-column"
        data-skin="Purple Service"
      >
        <div className="gdlr-core-pbf-column-content-margin gdlr-core-js ">
          <div className="gdlr-core-pbf-column-content clearfix gdlr-core-js ">
            <div className="gdlr-core-pbf-element">
              <div
                className="gdlr-core-column-service-item gdlr-core-item-pdb  gdlr-core-left-align gdlr-core-column-service-icon-left gdlr-core-no-caption gdlr-core-item-pdlr"
                style={{ paddingBottom: 30 }}
              >
                <div
                  className="gdlr-core-column-service-media gdlr-core-media-image"
                  style={{ marginTop: 10, marginRight: 30 }}
                >
                  <img src={this.props.icon} alt width={76} height={72} />
                </div>
                <div className="gdlr-core-column-service-content-wrapper">
                  <div className="gdlr-core-column-service-title-wrap">
                    <h3
                      className="gdlr-core-column-service-title"
                      style={{
                        fontSize: 22,
                        fontWeight: 600,
                        letterSpacing: 0,
                        textTransform: 'none',
                      }}
                    >
                      {this.props.title}
                    </h3>
                  </div>
                  <div
                    className="gdlr-core-column-service-content"
                    style={{ fontSize: 16 }}
                  >
                    <p>{this.props.description}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
