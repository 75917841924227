import React, { Component } from 'react'
import SocialLinkIcon from './SocialLinkIcon'

export default class ShareThisBlock extends Component {
  render() {
    return (
      <div
        className="gdlr-core-pbf-wrapper "
        style={{
          paddingTop: '55px',
          paddingHorizontal: 0,
          paddingBottom: '50px',
        }}
      >
        <div
          className="gdlr-core-pbf-background-wrap"
          style={{ backgroundColor: '#313787' }}
        />
        <div className="gdlr-core-pbf-wrapper-content gdlr-core-js ">
          <div className="gdlr-core-pbf-wrapper-container clearfix gdlr-core-container">
            <div className="gdlr-core-pbf-column gdlr-core-column-48 gdlr-core-column-first">
              <div className="gdlr-core-pbf-column-content-margin gdlr-core-js ">
                <div className="gdlr-core-pbf-column-content clearfix gdlr-core-js ">
                  <div className="gdlr-core-pbf-element">
                    <div
                      className="gdlr-core-title-item gdlr-core-item-pdb clearfix  gdlr-core-right-align gdlr-core-title-item-caption-top gdlr-core-item-pdlr"
                      style={{ paddingBottom: '10px' }}
                    >
                      <div className="gdlr-core-title-item-title-wrap ">
                        <div
                          className="gdlr-core-title-item-title gdlr-core-skin-title  gdlr-core-title-font"
                          style={{
                            fontSize: '18px',
                            fontWeight: '400',
                            letterSpacing: '0px',
                            textTransform: 'none',
                            color: '#ffffff',
                          }}
                        >
                          Share This<span className="gdlr-core-title-item-title-divider gdlr-core-skin-divider" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="gdlr-core-pbf-column gdlr-core-column-12">
              <div className="gdlr-core-pbf-column-content-margin gdlr-core-js ">
                <div className="gdlr-core-pbf-column-content clearfix gdlr-core-js ">
                  <div className="gdlr-core-pbf-element">
                    <div
                      className="gdlr-core-social-network-item gdlr-core-item-pdb  gdlr-core-left-align gdlr-core-item-pdlr"
                      style={{ paddingBottom: '0px' }}
                    >
                      <SocialLinkIcon
                        linkTo="https://www.linkedin.com/company/bora-growth-partners/"
                        className="gdlr-core-social-network-icon"
                        title="linkedin"
                        style={{ fontSize: '18px', color: '#ffffff' }}
                        icon="fa fa-linkedin"
                      />

                      <SocialLinkIcon
                        linkTo="https://twitter.com/BoraGrowth"
                        className="gdlr-core-social-network-icon"
                        title="twitter"
                        style={{ fontSize: '18px', color: '#ffffff' }}
                        icon="fa fa-twitter"
                      />
                      <SocialLinkIcon
                        linkTo="https://facebook.com/BoraGrowth"
                        className="gdlr-core-social-network-icon"
                        title="facebook"
                        style={{ fontSize: '18px', color: '#ffffff' }}
                        icon="fa fa-facebook"
                      />
                      <SocialLinkIcon
                        linkTo="https://instagram.com/BoraGrowth"
                        className="gdlr-core-social-network-icon"
                        title="instagram"
                        style={{ fontSize: '18px', color: '#ffffff' }}
                        icon="fa fa-instagram"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
