import React, { Component } from 'react'
import Layout from '../components/layout/layout'

import ServicesDetail from '../components/Services/ServicesDetail'

export default class PostInvestmentSupport extends Component {
  render() {
    const majapa = require('../assets/images/majapa.png')
    const mark = require('../assets/images/mark.jpg')
    const serviceImage = require('../assets/images/alex-knight-181471-unsplash.jpg')

    return (
      <Layout location={this.props.location}>
        <ServicesDetail
          service="Post Investment Support"
          serviceCaption="We provide expertise in capital management and execution"
          serviceDocumentLink="https://drive.google.com/file/d/14qbE2GgNBJtB1nSC02uqkoK9n_jt142D/view?usp=sharing"
          serviceHighlight={[
            'Business audit',
            'Capital drip plan',
            'Growth strategy & plan',
          ]}
          serviceDocumentLink="https://drive.google.com/file/d/14ktdRLmYCbH8MVA9GaavZbV3i0SI6tk7/view?usp=sharing"
          serviceTopDescription="At Bora Growth Partners, we assist business funding institutions and investment firms in de-risking their investments by conducting pre-investment due diligence and post-investment Growth support. We ensure the investment made to the businesses are utilized appropriately and offer capacity in fulfilling their respective growth mandates."
          serviceBottomDescription="Pre investment due diligence team conducts a business model audit in collaboration with investment client in reviewing unbiased commercial and operational value to be unlocked. Our recommendation at this phase focuses on Growth potential of the underlying investment from a commercial point of view"
          serviceImage={serviceImage}
          serviceSpecialists={[
            {
              name: 'Mohamed Majapa',
              title: 'Managing partner, Co-founder',
              linkedin: 'https://www.linkedin.com/in/mohamed-majapa-2a93a2b2/',
              twitter: 'https://twitter.com/Ti_Mj',
              image: majapa,
            },
            {
              name: 'Mark Schreiber',
              title: 'Senior consultant, business development',
              linkedin: 'https://za.linkedin.com/in/markaschreiber',
              twitter: 'https://twitter.com/MarkASchreiber',
              image: mark,
            },
          ]}
        />
      </Layout>
    )
  }
}
