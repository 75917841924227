import React, { Component } from 'react'
import { Link } from 'gatsby'
import ShareThisBlock from '../Social/ShareThisBlock'
import ServicesDetailHeader from './ServicesDetail/ServicesDetailHeader'
import ServicesDetailHero from './ServicesDetail/ServicesDetailHero'
import ServicesDetailLeftBlock from './ServicesDetail/ServicesDetailLeftBlock'
import ServicesDetailTeam from './ServicesDetail/ServicesDetailTeam'
import ServicesDetailApproachBlock from './ServicesDetail/ServicesDetailApproachBlock'
import EmployeeCard from '../About/EmployeeCard'
export default class ServicesDetail extends Component {
  renderServiceSpecialist() {
    return this.props.serviceSpecialists.map(specialist => {
      const { name, title, linkedin, twitter, image } = specialist
      return (
        <div>
          <EmployeeCard
            name={name}
            title={title}
            linkedin={linkedin}
            twitter={twitter}
            image={image}
          />
        </div>
      )
    })
  }

  renderServiceHighlights() {
    return this.props.serviceHighlight ? (
      this.props.serviceHighlight.map(highlight => {
        return (
          <li className=" gdlr-core-skin-divider">
            <span className="gdlr-core-icon-list-icon-wrap">
              <i
                className="gdlr-core-icon-list-icon fa fa-dot-circle-o"
                style={{
                  color: '#313787',
                  fontSize: 17,
                  width: 17,
                }}
              />
            </span>
            <span
              className="gdlr-core-icon-list-content"
              style={{ fontSize: 16 }}
            >
              {highlight}
            </span>
          </li>
        )
      })
    ) : (
      <div />
    )
  }

  render() {
    const aboutImage = require('../../assets/images/about-team-bg.jpg')
    const titleService = require('../../assets/images/title-service-bg.png')
    const shutterstock = require('../../assets/images/shutterstock_211211362.jpg')
    const icon1 = require('../../assets/images/icon-1.png')
    const icon2 = require('../../assets/images/icon-2.png')
    const personnel = require('../../assets/images/personnel-1-600x430.jpg')
    const personnel2 = require('../../assets/images/personnel-2-600x430.jpg')
    return (
      <div>
        <div className="gdlr-core-page-builder-body">
          <ServicesDetailHero withOpacity />
          <div
            className="gdlr-core-pbf-wrapper "
            style={{ padding: '10px 0px 10px 40px' }}
          >
            <div
              className="gdlr-core-pbf-background-wrap"
              style={{ backgroundColor: '#f5f5f5' }}
            />
            <ServicesDetailHeader title={this.props.service} />
          </div>
          <div
            className="gdlr-core-pbf-sidebar-wrapper "
            style={{ margin: '0px 0px 0px 40px' }}
          >
            <div className="gdlr-core-pbf-sidebar-container gdlr-core-line-height-0 clearfix gdlr-core-js gdlr-core-container">
              <div
                className="gdlr-core-pbf-sidebar-content  gdlr-core-column-45 gdlr-core-pbf-sidebar-padding gdlr-core-line-height gdlr-core-column-extend-right"
                style={{ padding: '60px 0px 0px 0px' }}
              >
                <div className="gdlr-core-pbf-sidebar-content-inner">
                  <div className="gdlr-core-pbf-column gdlr-core-column-60 gdlr-core-column-first">
                    <div
                      className="gdlr-core-pbf-column-content-margin gdlr-core-js  gdlr-core-column-extend-right"
                      style={{ padding: '50px 0px 30px 0px' }}
                    >
                      <div className="gdlr-core-pbf-background-wrap">
                        <div
                          className="gdlr-core-pbf-background gdlr-core-parallax gdlr-core-js"
                          style={{
                            backgroundImage: `url(${titleService})`,
                            backgroundRepeat: 'no-repeat',
                            backgroundPosition: 'top left',
                          }}
                          data-parallax-speed={0}
                        />
                      </div>
                      <div className="gdlr-core-pbf-column-content clearfix gdlr-core-js ">
                        <div className="gdlr-core-pbf-element">
                          <div className="gdlr-core-title-item gdlr-core-item-pdb clearfix  gdlr-core-left-align gdlr-core-title-item-caption-bottom gdlr-core-item-pdlr">
                            <div className="gdlr-core-title-item-title-wrap ">
                              <h3
                                className="gdlr-core-title-item-title gdlr-core-skin-title "
                                style={{
                                  fontSize: 60,
                                  fontWeight: 600,
                                  letterSpacing: 0,
                                  textTransform: 'none',
                                  color: '#3e4494',
                                }}
                              >
                                {this.props.service}
                                <span className="gdlr-core-title-item-title-divider gdlr-core-skin-divider" />
                              </h3>
                            </div>
                            <span
                              className="gdlr-core-title-item-caption gdlr-core-info-font gdlr-core-skin-caption"
                              style={{
                                fontSize: 13,
                                fontStyle: 'normal',
                                letterSpacing: 2,
                                textTransform: 'uppercase',
                                marginTop: 0,
                              }}
                            >
                              {this.props.serviceCaption}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="gdlr-core-pbf-column gdlr-core-column-60 gdlr-core-column-first">
                    <div className="gdlr-core-pbf-column-content-margin gdlr-core-js ">
                      <div className="gdlr-core-pbf-column-content clearfix gdlr-core-js ">
                        <div className="gdlr-core-pbf-element">
                          <div
                            className="gdlr-core-text-box-item gdlr-core-item-pdlr gdlr-core-item-pdb gdlr-core-left-align"
                            style={{ paddingBottom: 20 }}
                          >
                            <div className="gdlr-core-text-box-item-content">
                              <p>
                                {this.props.serviceTopDescription}
                                <br />
                                <img
                                  className="alignleft wp-image-5308"
                                  src={this.props.serviceImage}
                                  alt
                                  width={400}
                                  height={267}
                                />
                                <br />
                              </p>
                            </div>
                            <div className="gdlr-core-column-service-title-wrap">
                              <h3
                                className="gdlr-core-column-service-title"
                                style={{
                                  fontSize: 22,
                                  fontWeight: 600,
                                  letterSpacing: 0,
                                  textTransform: 'none',
                                }}
                              >
                                This is how we do it.
                              </h3>
                            </div>
                            <div
                              className="gdlr-core-icon-list-item gdlr-core-item-pdlr gdlr-core-item-pdb "
                              style={{ paddingBottom: 10 }}
                            >
                              <ul>{this.renderServiceHighlights()}</ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="gdlr-core-pbf-column gdlr-core-column-60 gdlr-core-column-first">
                    <div
                      className="gdlr-core-pbf-column-content-margin gdlr-core-js "
                      style={{ padding: '0px 0px 30px 0px' }}
                    >
                      <div className="gdlr-core-pbf-column-content clearfix gdlr-core-js ">
                        <div
                          className="gdlr-core-text-box-item gdlr-core-item-pdlr gdlr-core-item-pdb gdlr-core-left-align"
                          style={{ paddingBottom: 20 }}
                        >
                          <div className="gdlr-core-text-box-item-content">
                            <p>{this.props.serviceBottomDescription}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <ServicesDetailApproachBlock
                    icon={icon1}
                    title={
                      <p>
                        A Team <br />Of Professionals.
                      </p>
                    }
                    description="Our team of professionals is on deck to accomplish all your needs."
                  />
                  <ServicesDetailApproachBlock
                    icon={icon2}
                    title={
                      <p>
                        Analyze Your <br />Business
                      </p>
                    }
                    description="The first step to providing you value is by doing an audit to help us understand your business."
                  />

                  {/* <div
                    className="gdlr-core-pbf-column gdlr-core-column-60 gdlr-core-column-first"
                    data-skin="Purple Service"
                  >
                    <div className="gdlr-core-pbf-column-content-margin gdlr-core-js ">
                      <div className="gdlr-core-pbf-column-content clearfix gdlr-core-js ">
                        <div className="gdlr-core-pbf-element">
                          <div className="gdlr-core-accordion-item gdlr-core-item-pdlr gdlr-core-item-pdb  gdlr-core-accordion-style-box-icon">
                            <div className="gdlr-core-accordion-item-tab clearfix  gdlr-core-active">
                              <div className="gdlr-core-accordion-item-icon gdlr-core-js gdlr-core-skin-icon  gdlr-core-skin-e-background gdlr-core-skin-border" />
                              <div className="gdlr-core-accordion-item-content-wrapper">
                                <h4 className="gdlr-core-accordion-item-title gdlr-core-js ">
                                  Business Information
                                </h4>
                                <div className="gdlr-core-accordion-item-content">
                                  <p>
                                    Our Trading helps clients run and transform
                                    their front, middle and back-office trading
                                    operations. We provide buy-side, sell-side
                                    and market infrastructure firms with a
                                    full-service offering, including systems
                                    integration and technology consulting
                                    services, to assist in delivering high
                                    performance trading and settlement
                                    capabilities across all asset classes. This
                                    includes strategy, architecture design,
                                    operating model work, process improvement,
                                    systems building and trading p
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="gdlr-core-accordion-item-tab clearfix ">
                              <div className="gdlr-core-accordion-item-icon gdlr-core-js gdlr-core-skin-icon  gdlr-core-skin-e-background gdlr-core-skin-border" />
                              <div className="gdlr-core-accordion-item-content-wrapper">
                                <h4 className="gdlr-core-accordion-item-title gdlr-core-js ">
                                  Key Analyze
                                </h4>
                                <div className="gdlr-core-accordion-item-content">
                                  <p>
                                    Our Trading helps clients run and transform
                                    their front, middle and back-office trading
                                    operations. We provide buy-side, sell-side
                                    and market infrastructure firms with a
                                    full-service offering, including systems
                                    integration and technology consulting
                                    services, to assist in delivering high
                                    performance trading and settlement
                                    capabilities across all asset classes. This
                                    includes strategy, architecture design,
                                    operating model work, process improvement,
                                    systems building and trading p
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="gdlr-core-accordion-item-tab clearfix ">
                              <div className="gdlr-core-accordion-item-icon gdlr-core-js gdlr-core-skin-icon  gdlr-core-skin-e-background gdlr-core-skin-border" />
                              <div className="gdlr-core-accordion-item-content-wrapper">
                                <h4 className="gdlr-core-accordion-item-title gdlr-core-js ">
                                  Our Workflow &amp; Process
                                </h4>
                                <div className="gdlr-core-accordion-item-content">
                                  <p>
                                    Our Trading helps clients run and transform
                                    their front, middle and back-office trading
                                    operations. We provide buy-side, sell-side
                                    and market infrastructure firms with a
                                    full-service offering, including systems
                                    integration and technology consulting
                                    services, to assist in delivering high
                                    performance trading and settlement
                                    capabilities across all asset classes. This
                                    includes strategy, architecture design,
                                    operating model work, process improvement,
                                    systems building and trading p
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="gdlr-core-pbf-column gdlr-core-column-60 gdlr-core-column-first">
                    <div
                      className="gdlr-core-pbf-column-content-margin gdlr-core-js "
                      style={{ padding: '0px 0px 25px 0px' }}
                    >
                      <div className="gdlr-core-pbf-column-content clearfix gdlr-core-js ">
                        <div className="gdlr-core-pbf-element">
                          <div
                            className="gdlr-core-text-box-item gdlr-core-item-pdlr gdlr-core-item-pdb gdlr-core-left-align"
                            style={{ paddingBottom: 20 }}
                          >
                            <div className="gdlr-core-text-box-item-content">
                              <p>
                                Our Trading helps clients run and transform
                                their front, middle and back-office trading
                                operations. We provide buy-side, sell-side and
                                market infrastructure firms with a full-service
                                offering, including systems integration and
                                technology consulting services, to assist in
                                delivering high performance trading and
                                settlement capabilities across all asset
                                classes. This includes strategy, architecture
                                design, operating model work, process
                                improvement, systems building and trading p
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> */}
                  <ServicesDetailTeam
                    personnel1={personnel}
                    personnel2={personnel2}
                    service={this.props.service}
                    specialists={this.renderServiceSpecialist()}
                  />
                </div>
              </div>
              <ServicesDetailLeftBlock
                service={this.props.service}
                serviceDocumentLink={this.props.serviceDocumentLink}
              />
            </div>
          </div>
        </div>
        <ShareThisBlock />
      </div>
    )
  }
}
