import React, { Component } from 'react'
import { Link } from 'gatsby'
export default class ServicesDetailLeftBlock extends Component {
  render() {
    return (
      <div
        className="gdlr-core-pbf-sidebar-left gdlr-core-column-extend-left  bora-sidebar-area gdlr-core-column-15 gdlr-core-pbf-sidebar-padding  gdlr-core-line-height"
        style={{ padding: '60px 25px 30px 0px' }}
      >
        <div className="gdlr-core-sidebar-item">
          <div id="text-9" className="widget widget_text bora-widget">
            <div className="textwidget">
              <h3 className="large-widget-title">Our Services</h3>{' '}
              <span
                className="gdlr-core-space-shortcode"
                style={{ marginTop: '-25px' }}
              />
            </div>
          </div>
          <div id="nav_menu-3" className="widget widget_nav_menu bora-widget">
            <div className="menu-our-services-container">
              <ul id="menu-our-services" className="menu">
                <li className="menu-item current_page_item menu-item-5453">
                  <Link to="/business-strategy">Business Strategy</Link>
                </li>
                <li className="menu-item">
                  <Link to="/technology-advisory">Technology Advisory</Link>
                </li>
                <li className="menu-item">
                  <Link to="/market-research">Market Research</Link>
                </li>
                <li className="menu-item">
                  <Link to="/post-investment-support">
                    Post Investment Support
                  </Link>
                </li>
                <li className="menu-item">
                  <Link to="/creative-services">Creative Services</Link>
                </li>
              </ul>
            </div>
          </div>
          <div id="text-8" className="widget widget_text bora-widget">
            <div className="textwidget">
              <p />
              <div
                className="gdlr-core-widget-box-shortcode"
                style={{
                  color: '#ffffff',
                  padding: '35px 35px 15px',
                  backgroundColor: '#313787',
                }}
              >
                <div className="gdlr-core-widget-box-shortcode-content">
                  <p>
                    <span
                      style={{
                        letterSpacing: 1,
                        fontWeight: 600,
                        fontSize: 15,
                      }}
                    >
                      GET THE BROCHURE
                    </span>
                    <i
                      className="fa fa-file-pdf-o"
                      style={{
                        fontSize: 13,
                        color: '#f68b46',
                        marginLeft: 10,
                      }}
                    />
                    <span
                      className="gdlr-core-space-shortcode"
                      style={{ marginTop: 10 }}
                    />
                    <span style={{ color: '#a3a9f6' }}>
                      Download the pdf file of latest update for{' '}
                      {this.props.service}.
                    </span>
                  </p>
                </div>
                <a
                  className="gdlr-core-widget-box-shortcode-link"
                  href={this.props.serviceDocumentLink}
                  target="_blank"
                />
              </div>
              <span
                className="gdlr-core-space-shortcode"
                style={{ marginTop: 25 }}
              />
              <div
                className="gdlr-core-widget-box-shortcode"
                style={{
                  color: '#3b3b3b',
                  padding: '35px 35px 15px',
                  border: '2px solid #313787',
                }}
              >
                <h3
                  className="gdlr-core-widget-box-shortcode-title"
                  style={{ color: '#3e4494' }}
                >
                  Need Help ?
                </h3>
                <div className="gdlr-core-widget-box-shortcode-content">
                  <p>
                    Please feel free to contact us. We will get back to you with
                    1-2 business days. Or just call us now
                  </p>
                  <p>
                    <span style={{ fontSize: 16 }}>
                      <i
                        className="fa fa-phone"
                        style={{
                          fontSize: 16,
                          color: '#f68b46',
                          marginRight: 10,
                        }}
                      />{' '}
                      <a href="tel:+27-0214182812">+27-0214182812</a>
                    </span>
                    <br />{' '}
                    <span style={{ fontSize: 16 }}>
                      <i
                        className="fa fa-envelope-open-o"
                        style={{
                          fontSize: 16,
                          color: '#f68b46',
                          marginRight: 10,
                        }}
                      />{' '}
                      <a href="mailto:info@boragrowth.com">
                        info@boragrowth.com
                      </a>
                    </span>
                  </p>
                </div>
              </div>
              <span
                className="gdlr-core-space-shortcode"
                style={{ marginTop: 25 }}
              />
              <Link
                className="gdlr-core-button  gdlr-core-button-solid gdlr-core-button-no-border gdlr-core-button-full-width"
                to="/contact"
                style={{
                  padding: '19px 29px',
                  borderRadius: 3,
                  MozBorderRadius: 3,
                  WebkitBorderRadius: 3,
                  background: '#e96f20',
                }}
              >
                <span className="gdlr-core-content">Get A Quote</span>
                <i className="gdlr-core-pos-right fa fa-long-arrow-right" />
              </Link>
              <p />
            </div>
          </div>
        </div>
      </div>
    )
  }
}
