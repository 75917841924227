import React, { Component } from 'react'
import EmployeeCard from '../../About/EmployeeCard'

export default class ServicesDetailTeam extends Component {
  render() {
    return (
      <div
        className="gdlr-core-pbf-column gdlr-core-column-60 gdlr-core-column-first"
        data-skin="Purple Service"
      >
        <div className="gdlr-core-pbf-element">
          <div
            className="gdlr-core-title-item gdlr-core-item-pdb clearfix  gdlr-core-left-align gdlr-core-title-item-caption-top gdlr-core-item-pdlr"
            style={{ paddingBottom: 40 }}
          >
            <div className="gdlr-core-title-item-title-wrap ">
              <h3
                className="gdlr-core-title-item-title gdlr-core-skin-title "
                style={{
                  fontSize: 28,
                  fontWeight: 600,
                  letterSpacing: 0,
                  textTransform: 'none',
                  color: '#232323',
                }}
              >
                {this.props.service} Specialists<span className="gdlr-core-title-item-title-divider gdlr-core-skin-divider" />
              </h3>
            </div>
          </div>
        </div>
        <div className="gdlr-core-pbf-element">
          <div
            className="gdlr-core-personnel-item gdlr-core-item-pdb clearfix  gdlr-core-left-align gdlr-core-personnel-item-style-grid gdlr-core-personnel-style-grid gdlr-core-with-divider "
            style={{ paddingBottom: 20 }}
          >
            {this.props.specialists}
          </div>
        </div>
      </div>
    )
  }
}
