import React, { Component } from 'react'

export default class EmployeeCard extends Component {
  render() {
    //-webkit-user-drag: none;
    return (
      <div className="gdlr-core-personnel-list-column  gdlr-core-column-20 gdlr-core-item-pdlr ">
        <div className="gdlr-core-personnel-list clearfix">
          <div className="gdlr-core-personnel-list-image gdlr-core-media-image  gdlr-core-opacity-on-hover gdlr-core-zoom-on-hover">
            <img src={this.props.image} width={600} height={465} />
          </div>
          <div className="gdlr-core-personnel-list-content-wrap">
            <h3
              className="gdlr-core-personnel-list-title"
              style={{ fontWeight: 600 }}
            >
              {this.props.name}
            </h3>
            <div
              className="gdlr-core-personnel-list-position gdlr-core-info-font gdlr-core-skin-caption"
              style={{ fontSize: 15, fontStyle: 'normal' }}
            >
              {this.props.title}
            </div>
            <div className="gdlr-core-personnel-list-divider gdlr-core-skin-divider" />
            {/* <div className="gdlr-core-personnel-list-content">
              <p>
                +1-334-3452-345
                <br /> <a href="mailto:ss@ss.ss">{this.props.email ? this.props.email : ''}</a>
              </p>
            </div> */}
            <div className="gdlr-core-personnel-list-content">
              <p>{this.props.bio}</p>
            </div>
            <div className="gdlr-core-personnel-list-social">
              <div
                className="gdlr-core-social-network-item gdlr-core-item-pdb  gdlr-core-none-align"
                style={{ paddingBottom: 0 }}
              >
                <a
                  href={
                    this.props.linkedin
                      ? this.props.linkedin
                      : 'https://www.linkedin.com/company/bora-growth-partners'
                  }
                  target="_blank"
                  className="gdlr-core-social-network-icon"
                  title="linkedin"
                >
                  <i className="fa fa-linkedin" />
                </a>
                <a
                  href={
                    this.props.twitter
                      ? this.props.twitter
                      : 'https://twitter.com/BoraGrowth'
                  }
                  target="_blank"
                  className="gdlr-core-social-network-icon"
                  title="twitter"
                >
                  <i className="fa fa-twitter" />
                </a>
                <a
                  href={
                    this.props.facebook
                      ? this.props.facebook
                      : 'https://facebook.com/BoraGrowth'
                  }
                  target="_blank"
                  className="gdlr-core-social-network-icon"
                  title="facebook"
                >
                  <i className="fa fa-facebook" />
                </a>
                <a
                  href={
                    this.props.instagram
                      ? this.props.instagram
                      : 'https://instagram.com/BoraGrowth'
                  }
                  target="_blank"
                  className="gdlr-core-social-network-icon"
                  title="instagram"
                >
                  <i className="fa fa-instagram" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
